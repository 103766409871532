<script setup  lang="ts">
const route = useRoute();
const { t } = useNuxtApp().$i18n;
const head = useLocaleHead({ seo: true });
const title = computed(() => route.meta.title ? `${t(route.meta.title as string)} - Top of Travel | jungfrau.ch` : 'Top of Travel | jungfrau.ch');

useHead({
    bodyAttrs: {
        class: [
            'bg-background-1',
            'typo-copy',
            'text-brand-b',
        ].join(' '),
    },
});
</script>

<template>
    <div>
        <Html :lang="head.htmlAttrs?.lang">
            <Head>
                <Title>{{ title }}</Title>
            </Head>
            <Body>
                <div
                    class="
                    grid
                    min-h-dvh
                    grid-rows-[min-content_auto]
                "
                >
                    <div class="sticky top-0 z-[21]">
                        <TheToasts />
                    </div>

                    <div>
                        <slot />
                    </div>

                    <TheDevHelper v-if="useRuntimeConfig().public.enableDevHelpers" />
                    <TheLoadingOverlay />
                    <TheConfirmDialog />
                </div>
            </Body>
        </Html>
    </div>
</template>
