<script setup  lang="ts">
import { addDays, startOfDay } from 'date-fns';

const localeRoute = useLocaleRoute();

const { addToast, reset: resetToasts } = useToasts();

function resetAll() {
    resetAllStores();
    resetToasts();
    navigateTo('/');
}

function addTestToast() {
    addToast(`key-${Math.random()}`, Math.random() > 0.5 ? 'info' : 'error', 'Aut doloremque perspiciatis repellat dolorem et sit molestiae. Cum labore cupiditate. Ab quisquam incidunt suscipit deserunt molestiae officiis.', 0, true);
}

function addTestSkiProduct() {
    const shopStore = useShopStore();

    const productConfig = configProductSki;

    shopStore.addProduct(
        productConfig.key,
        {
            config: productConfig,
            dateFrom: startOfDay(zurichDate()),
            dateUntil: addDays(startOfDay(zurichDate()), 2),
            duration: 'PT48H',
            passengerRefs: [],
            availableFulfillmentOptions: [{ type: 'PASS_REFERENCE', media: 'PDF_A4' }, { type: 'PASS_REFERENCE', media: 'SWISSPASS' }],
        },
    );
}

const visible = ref(false);
</script>

<template>
    <div class="absolute left-0 top-1/2  z-50 origin-top-left -rotate-90 print:hidden">
        <div class="text-sm -mt-14 flex h-14 space-x-2 rounded bg-brand-b/20 p-4 pt-10 text-white transition-transform hover:translate-y-8" :class="{ 'translate-y-8 !bg-brand-b/95': visible }">
            <div class="flex items-center space-x-3">
                <NuxtLink :to="localeRoute({ name: 'offerFlow-ski-steps-step-1-date' })">
                    SKI
                </NuxtLink>

                <NuxtLinkLocale :to="{ name: 'offerFlow-swp-steps-step-1-date' }">
                    SWP
                </NuxtLinkLocale>

                <LanguageSwitcher />

                <button @click="resetAll">
                    Reset
                </button>

                <button @click="addTestToast">
                    Toast
                </button>

                <button @click="addTestSkiProduct">
                    Ski Prod
                </button>
            </div>

            <div class="absolute top-full h-3 w-7 rounded-b-sm border-2 border-t-0 border-dashed border-shade-3 bg-brand-b/20" @click="visible = !visible" />
        </div>
    </div>
</template>
